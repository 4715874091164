import styled from "styled-components"
import { Translations } from "../Lang"
import { createContext, useContext, useEffect, useState } from "react"
import { LangContext } from "../../../App"
import axios from "axios"
import {__server, __countries} from "../../../Api"

import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import deLocale from 'i18n-iso-countries/langs/de.json';
import SelectSearch from "../Components/SelectSearch"

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { pl, de, enGB } from 'date-fns/locale';
registerLocale('pl', pl);
registerLocale('de', de);
registerLocale('en', enGB);

const SearchBox = styled.div`
  width: 1260px;
  margin: auto;
  margin-top: 66px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  padding: 20px 32px;
  backdrop-filter: blur(1.5px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 120px;

  @media (max-width: 767px) {
    margin-top: 0px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
  }

  
`

const InnerSearchBox = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 34px 4px 30px 0px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 7px 30px;
  display: flex;
  align-items: center;
  gap: 13px;

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 18px 20px;
    gap: 24px 50px;
    box-shadow: none;

    background-image: url(/menu/bg-search.svg);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
`

const SearchButton = styled.button`
  border: none;
  border-radius: 10px;
  background-color: #32c8d2;
  box-sizing: border-box;
  padding: 21px 45px;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  color: #fff;
`

const SearchProp = styled.div`
  width: 133px;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const SearchLabel = styled.div`
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 300;
  color: #878b93;
  text-transform: uppercase;
`

const SearchSelectBox = styled.div`
  display: flex;
`

const SearchSelect = styled.select`
  width: calc(120px + 13px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  color: #878b93;
  z-index: 999;
  cursor: pointer;
`
const SearchDate = styled.input`
  width: 120px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  color: #878b93;
  cursor: pointer;
`

const SearchDropIcon = styled.img`
  width: 13px;
  margin-left: -13px;
  z-index: 9;
`

const SearchPropDivider = styled.div`
  height: 46px;
  width: 1px;
  background-color: #878b93;

  @media (max-width: 767px) {
    display: none;
  }
`

const FormWrapper = styled.form`
  width: 100%;
  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
    margin-bottom: 20px;
  }

  * {
    z-index: 9999;
  }
`

const DatePickerStyle = styled.div`
  input
  {
    width: 100%;
    border: none;
    outline: none;
    font-family: 'Gilroy-Light';
    font-size: 16px;
    font-weight: 400;
  }
`

export const SearchFilterContext = createContext();

export default function MenuSearcher ({})
{ 
  const { lang } = useContext(LangContext);
  countries.registerLocale(plLocale);
  countries.registerLocale(enLocale);
  countries.registerLocale(deLocale);

  const [filters, setFilters] = useState({
    start: null,
    days: null,
    yachtCharter: null,
    country: null,
    cabins: null,
    heads: null
  });

  useEffect(() => {

    const params = new URLSearchParams(window.location.search);
    const newFilters = {...filters};

    for (const key of Object.keys(filters)) {
      if (params.has(key)) {
        newFilters[key] = params.get(key);
      }
    }

    setFilters(newFilters);

  }, []);

  /**
   * Wartości w wyszkiwarce
   */
  /* const [countryList, setCountryList] = useState([]); */

  /**
   * Ustawienia z API
   */
  useEffect(() => {
    /** axios.get(`${__server}/front/pages/countries`).then((resp) => {
      setCountryList(resp.data.map((code) => {
        return {
          value: code,
          label: countries.getName(code, lang)
        }
      }))
    }).catch((err) => {
      console.log('[hero menu]', err);
    }) */
  }, []);


  const convertTranslationToOptions = function (obj) 
  {
    return Object.keys(obj).map((key) => {
      return {
        value: key,
        label: obj[key]
      }
    })
  }

  const onFormSubmit = function (e)
  {
    e.preventDefault();

    const filtersWithoutNulls = {};

    for (const key of Object.keys(filters)) {
      if (filters[key] !== null) {
        filtersWithoutNulls[key] = filters[key];
      }
    }

    const start = new Date(filtersWithoutNulls['start']);
    const yyyy = start.getFullYear();
    const mm = String(start.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const dd = String(start.getDate()).padStart(2, '0');

    filtersWithoutNulls['start'] = `${yyyy}-${mm}-${dd}`;

    const searchParams = new URLSearchParams(filtersWithoutNulls);
    window.location.href = `/oferty?${searchParams.toString()}`;
  }

  const isDateValid = function (date)
  {
    const dateObj = new Date(date);
    return dateObj.getUTCDay() == 5 && dateObj > new Date();
  }

  return (
    <SearchFilterContext.Provider value={{filters, setFilters}} >
      <FormWrapper onSubmit={onFormSubmit}>
        <SearchBox>
          <InnerSearchBox>
            {/* ---------------------------------------- */}
            <SearchProp>
              <SearchLabel>
                {Translations[lang].slCountry}
              </SearchLabel>
              <SelectSearch
                name="country"
                placeholder={Translations[lang].spCountry}
                isSearchable
                options={__countries.map((code) => {
								    return {
									     value: code,
									     label: countries.getName(code, lang)
								    }
								})}
              />
            </SearchProp>
            {/* ---------------------------------------- */}
            <SearchPropDivider />
            {/* ---------------------------------------- */}
            <SearchProp>
              <SearchLabel>
                {Translations[lang].slYachtType}
              </SearchLabel>
              <SelectSearch 
                name="kind" 
                placeholder={Translations[lang].spYachtType}
                isSearchable={false} 
                options={Translations[lang].spYachtTypes}
              />
            </SearchProp>
            {/* ---------------------------------------- */}
            <SearchPropDivider />
            {/* ---------------------------------------- */}
            <SearchProp>
              <SearchLabel>
                {Translations[lang].slDate}
              </SearchLabel>
              <SearchSelectBox>
                <DatePickerStyle>
                  <DatePicker
                    locale={lang}
                    selected={filters.start}
                    onChange={(date) => setFilters({...filters, start: date})}
                    filterDate={isDateValid}
                    placeholderText="Wybierz datę"
                    required
                    dateFormat="yyyy-MM-dd"
                  />
                </DatePickerStyle>
                {/* <SearchDate
                  type="date"
                  name="start"
                  required
                  value={filters.start ?? ""}
                  onChange={(e) => setFilters({...filters, start: e.target.value})}
                />*/}
              </SearchSelectBox>
            </SearchProp>
            {/* ---------------------------------------- */}
            <SearchPropDivider />
            {/* ---------------------------------------- */}
            <SearchProp>
              <SearchLabel>
                {Translations[lang].slDays}
              </SearchLabel>
              <SelectSearch
                required
                name="days"
                placeholder={Translations[lang].spChoose}
                isSearchable={false}
                options={convertTranslationToOptions(Translations[lang].spDays)}
              />
            </SearchProp>
            {/* ---------------------------------------- */}
            <SearchPropDivider />
            {/* ---------------------------------------- */}
            <SearchProp>
              <SearchLabel>
                {Translations[lang].slCabs}
              </SearchLabel>
              <SelectSearch
                name="cabins"
                placeholder={Translations[lang].spChoose}
                isSearchable={false}
                options={convertTranslationToOptions(Translations[lang].spCabins)}
              />
            </SearchProp>
            {/* ---------------------------------------- */}
            <SearchPropDivider />
            {/* ---------------------------------------- */}
            <SearchProp>
              <SearchLabel>
                {Translations[lang].slHeads}
              </SearchLabel>
              <SelectSearch
                name="heads"
                placeholder={Translations[lang].spChoose}
                isSearchable={false}
                options={convertTranslationToOptions(Translations[lang].spHeads)}
              />
            </SearchProp>
            {/* ---------------------------------------- */}
          </InnerSearchBox>
          <SearchButton>{Translations[lang].search}</SearchButton>
        </SearchBox>
      </FormWrapper>
    </SearchFilterContext.Provider>
  )
}
