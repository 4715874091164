import { Translations as MenuTranslations } from "../../../Components/Menu/Lang"
import { useContext } from "react";
import { LangContext } from "../../../App";
import styled from "styled-components";
import { OffersFilterContext } from "../OfertyPage";
import RowInput from "./RowInput";

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { pl, de, enGB } from 'date-fns/locale';
registerLocale('pl', pl);
registerLocale('de', de);
registerLocale('en', enGB);

const Datebox = styled.input.attrs({type: "date"})`
  width: 100%;
  margin-top: 6px;
  color: hsl(0, 0%, 20%);
  background-color: #fff;
  outline: none;
  border: 1px solid #32C8D2;
  border-radius: 4px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  box-sizing: border-box;
  padding: 9px 8px;
  
  &:focus {
    box-shadow: 0 0 0 1px #3792ca;
  }
  
  &::-webkit-calendar-picker-indicator {
    opacity: 0; 
  }

  &::before {
    content: '';
    position: absolute;
    opacity: 0.3;
    width: 20px;
    height: 20px;
    background-image: url('/ico/chevron-input.svg');
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
    translate: 248px;
  }
  
  &:hover::before {
    opacity: 0.5;
  }
`

/*<Datebox 
  value={filters.start}
  onChange={(e) => setFilters({...filters, start: e.target.value})}
/>*/

const DatePickerStyle = styled.div`
  margin-top: 5px;

  input
  {
    width: 285px;
    border: 1px solid #32c8d2;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 11px 10px;
    color: #133b51;
    outline: none;
    font-family: 'Gilroy-Light';
    font-size: 16px;
    font-weight: 400;
  }

  .react-datepicker__tab-loop * {
    z-index: 9999;
  }
`

const isDateValid = function (date)
{
  const dateObj = new Date(date);
  return dateObj.getUTCDay() == 5 && dateObj > new Date();
}

const shitToDate = function (shit)
{
    const yyyy = shit.getFullYear();
    const mm = String(shit.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const dd = String(shit.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
}


export default function CallendarInput ()
{
  const { lang } = useContext(LangContext);
  const { filters, setFilters } = useContext(OffersFilterContext);

  return (
    <RowInput
      label={MenuTranslations[lang].spDate}
      htInput={
        <DatePickerStyle>
          <DatePicker
            locale={lang}
            selected={filters.start}
            onChange={(date) => setFilters({...filters, start: shitToDate(date)})}
            filterDate={isDateValid}
            placeholderText="Wybierz datę"
            required
            dateFormat="yyyy-MM-dd"
          />
        </DatePickerStyle>
      }     
    />
  )
}
