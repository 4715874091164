import { useContext } from "react";
import SelectRow from "./SelectRow";
import axios from "axios";
import { LangContext } from "../../../App";

import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import deLocale from 'i18n-iso-countries/langs/de.json';

import { Translations as MenuTranslations } from "../../../Components/Menu/Lang"

import { OffersBasesContext } from "../OfertyPage";

export default function BaseInput ()
{
  const { lang } = useContext(LangContext);
  const { bases } = useContext(OffersBasesContext);

  countries.registerLocale(plLocale);
  countries.registerLocale(enLocale);
  countries.registerLocale(deLocale);

  console.log(bases);

  const predBasesSort = (a, b) =>
    a.name.localeCompare(b.name);

  return (
    <SelectRow
      name="base" 
      options={bases.sort(predBasesSort).map((base) => {
        return {
          label: base.name,
          value: base.id
        }
      })} 
      label={MenuTranslations[lang].slBase} 
      placeholder={MenuTranslations[lang].spBase} 
      isClearable={true}
      isSearchable={true}
    />
  )
}
