import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext, useEffect, useState } from "react"
import { LangContext } from "../../../../App"

import { motion } from 'framer-motion'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  width: 100%;
  overflow: hidden;

  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 16px;
  color: #133b51;

  > b {
    font-family: 'Gilroy-ExtraBold';
  }
`

const Button = styled.button`
  margin-top: 28px;
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
  color: #1a87c5;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Icon = styled.img`
  margin-left: 11px;
  width: 15px;
  transform: rotate(${params => params.$isMore ? '180deg' : '0deg'});
`

/**
 * 
 * WARN: Jak się nie da maxHeight to się dostosuje do szerokości konentu
 * @returns 
 */
export default function ReadMore ({ children, minHeight, maxHeight, openOnStart = false })
{
  const { lang } = useContext(LangContext);
  const [isMore, setIsMore] = useState(openOnStart);

  return (
    <Wrapper>
      <motion.div

        style={{overflow: 'hidden'}}
        variants={{
          open: {height: maxHeight},
          close: {height: minHeight}
        }} 

        animate={isMore ? 'open' : 'close'}
        transition={{ duration: 0.6 }}
      >
        <Content $minHeight={minHeight}>
          {children}
        </Content>
      </motion.div>
      <Button onClick={(e) => setIsMore(!isMore)}> 
        {Translations[lang].readMore[isMore ? 'lessButton' : 'moreButton']}
        <Icon src="/ico/read-more-arrow.svg" $isMore={isMore} />
      </Button>
    </Wrapper>
  )
}