export const Translations = {
  pl: {
    pageTitle: "Forsail - Incentive",
    specialEvents: {
      title: <>Eventy <span>SPECJALNE</span></>,
      btn: "Sprawdź pozostałe",
      items: [
        "SKI INCENTIVE",
        "ARKTYCZNE SAFARI W JAPONI",
        "SPARTAKIADY SPORTOWE",
        "PIKNIKI"
      ]
    },
    intro: {
      title:
        "„Jaki jesteś na lądzie pokaże morze, jak zmieniło cię morze pokaże ląd.” ",
      descr: (
        <>
          W dawnych czasach wspólny wysiłek setek rąk załogi na pokładzie
          żaglowców, pomagał doprowadzić statek do portu bezpiecznego
          schronienia oraz osiągać nieznane i odległe cele podróży, co
          gwarantowało niezliczone korzyści. W czasach dzisiejszych okrętem
          takim jest firma. Żeglowanie po burzliwym oceanie rynku wymaga
          bezustannego zaangażowania załogi, wzajemnego zaufania i ducha
          współpracy oraz odwagi i r sądku kapitana, aby osiągnąć zamierzone
          cele, zdobyć nowe rynki i z pełnymi wiatru żaglami zostawić
          konkurencję za sobą.
          <br />
          <br />
          Jako pionier i promotor idei Incentive pod żaglami jesteśmy firmą z
          największym na rynku doświadczeniem w organizacji tego typu imprez .
          SailIncentive™ to doskonała metoda budowania, motywacji i integracji
          zespołu, oparta na analogii pomiędzy pracą załogi na jachcie, a pracą
          jednostki organizacyjnej w firmie. SailIncentive™ to również narzędzie
          oceny charakterów i wyłaniania naturalnych liderów, doskonale
          sprawdzające się w procesie ewaluacji i awansowania pracowników.
          <br />
          <br />
          Pasja i zamiłowanie do wykonywanej pracy, a także wieloletnie
          doświadczenie pozwalają nam na perfekcyjną organizację motywacyjnych
          imprez żeglarskich w najciekawszych zakątkach świata. A zatem – All
          hands on deck! Wszystkie ręce na pokład!
        </>
      ),
    },
    regattas: {
      title: (
        <>
          Nasze <span>Regaty Firmowe</span>
        </>
      ),
      descr:
        "Regaty żeglarskie to doskonała forma integracji i motywacji pracowników oraz wykorzystanie analogii pracy załogi na jachcie oraz zespołu pracującego w firmie. Regaty żeglarskie to także doskonała forma promocji i budowania wizerunku firmy.  ",
      button: "Sprawdź Regaty Firmowe ",
    },
    managementCurses: {
      title: (
        <>
          Oferta <span>Szkoleń Menadżerskiech</span>
        </>
      ),
      descr: (
        <>
          Rozwój umiejętności przywódczych, wsparcie w rozwoju osobistym i
          motywacja do osiągania wyższych celów – wszystko to podczas
          innowacyjnego kursu na otwartym morzu!
          <br />
          <br />
          Wspólnie łączymy edukację z odpoczynkiem!
        </>
      ),
      button: "Sprawdź Szkolenia ",
      checkOutButton: "SPRAWDŹ",
      localization: "Lokalizacja",
      offers: [
        {
          title: "BUDOWANIE ZESPOŁU",
          countryCode: "HRV",
        },
        {
          title: "Narzędzia coachingowe w zarządzaniu zespołem ",
          countryCode: "GRC",
        },
      ],
    },
  },
};

Translations.en = Translations.pl;
Translations.de = Translations.pl;
