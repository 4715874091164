import { useContext } from "react"
import styled from "styled-components"
import { LangContext } from "../../App"
import { Translations } from "./Lang"

import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import deLocale from 'i18n-iso-countries/langs/de.json';

const Wrapper = styled.div`
  margin-top: 100px;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 32px;
  color: #133b51;
  width: 311px;

  > span {
    font-family: 'Gilroy-ExtraBold';
    color: #32c8d2;
  }
  
  @media (max-width: 767px) {
  	font-size: 24px;
  	width: 233px;
  }
`

const Btn = styled.a`
  text-decoration: none;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #32c8d2;
  background-color: transparent;
  box-sizing: border-box;
  padding: 15px 31px;
  border-radius: 10px;
  border: 2px solid #32c8d2;
  height: fit-content;

  @media (max-width: 767px) {
 		width: fit-content; 
  }
`

const DesktopButtonWrapper = styled.div`
	display: block;
	@media (max-width: 767px) {
		display: none;
	}
`

const MobileButtonWrapper = styled.div`
	display: none;
	@media (max-width: 767px) {
		margin-top: 25px;
		display: flex;
		justify-content: center;
	}
`

const Cards = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 52px;

	@media (max-width: 767px) {
		margin-top: 25px;
		flex-wrap: nowrap;
		gap: 10px;
		justify-content: flex-start;
		overflow: scroll;
		width: fit-content;
		box-sizing: border-box;
		padding: 0px 15px;
	}
`

const CardWrapper = styled.div`
	@media (max-width: 767px) {
		width: 100%;
		overflow: scroll;
		overflow-y: hidden;
		scroll-snap-align: center;
	}
`

const Card = styled.a`
  width: 290px;
  height: 280px;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  background-image: url(/home/charter/${params => params.$bg}.webp);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

	@media (max-width: 767px) {
		width: 194px;
		height: 187px;
	}
`

const Label = styled.div`
	background-color: #fff;
	box-sizing: border-box;
	padding: 7px 20px;
	border-radius: 32px;
	font-family: 'Manrope';
	font-size: 14px;
	font-weight: 800;
	color: #133b51;
	text-transform: uppercase;

	@media (max-width: 767px) {
		font-size: 12px;
		padding: 5px 10px;
	}
`

export default function RecomendedCharters ({})
{
  const { lang } = useContext(LangContext);

  const countriesList = ['CRI', 'GRC', 'ITA', 'SYC', 'POL'];
  const displayCountries = [];

  /** Wyświetlamy 4 losowe */
  for (let i = 0; i < 4; i++) {
    const idx = parseInt(Math.random() * countriesList.length);
    displayCountries.push(countriesList.splice(idx, 1)[0]);
  }
  
  countries.registerLocale(plLocale);
  countries.registerLocale(enLocale);
  countries.registerLocale(deLocale);

  return (
    <Wrapper>
      <Top>
        <Title>
          {Translations[lang].recomended.title}
        </Title>
        <DesktopButtonWrapper>
	        <Btn href="/nasze-destynacje">
		        {Translations[lang].recomended.btn}
	        </Btn>
        </DesktopButtonWrapper>
      </Top>
      <CardWrapper>
				<Cards>
					{displayCountries.map((country, key) => (
						<Card $bg={country} key={key}>
							<Label>
								{countries.getName(country, lang)}
							</Label>
						</Card>
					))}
				</Cards>
      </CardWrapper>
      <MobileButtonWrapper>
	      <Btn href="/nasze-destynacje">
		      {Translations[lang].recomended.btn}
	      </Btn>
      </MobileButtonWrapper>
    </Wrapper>
  )
}
