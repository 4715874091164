import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../App"
import { formatNumberByCountry } from "../../utils/number-formatter"
import { __renderable_equipment } from "../Yacht/View/Equipment"
import { redirectDocument } from "react-router-dom"
import SkeletonItem from "./SkeletonItem"

const Wrapper = styled.a`
  display: grid;
  grid-template-columns: 0.25fr 0.5fr 0.25fr;

  text-decoration: none;

  box-sizing: border-box;
  padding-bottom: 25px;
  border-bottom: 1px solid #32c8d2;
  gap: 17px;

  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0px;
  }
`

const Image = styled.div`
  background-image: url(${params => params.$src});
  background-position: center center;
  background-size: cover;

  border-radius: 5px;

  @media (max-width: 767px) {
    order: 2;
    margin-top: 10px;
    width: 49%;
    height: 230px;
  }
`

const Info = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    order: 1;
  } 
`

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 767px) {
    margin-top: 10px;
    order: 3;
  } 
`




const Title = styled.a`
  text-decoration: none;
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  color: #32c8d2;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 24px;
  }
`

const Details = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Gilroy-Light';
  font-size: 14px;

  > span {
    color: #32c8d2;
    font-size: 16px;
  }

  > img {
    width: 25px;
    margin-top: -8px;
  }

  > span > img {
    width: 15px;
    height: 15px;
    translate: 0px 2px;
  }

  @media (max-width: 767px) {
    margin-top: 4px;

    > img {
      width: 19px;
    }
  }
`

const Props = styled.div`
  width: fit-content;
  height: fit-content;
  margin-top: 10px;
  margin-bottom: 8px;
  border: 1px solid #32c8d2;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 15px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 70px;

  @media (max-width: 767px) {
    width: 100%;
    marign-bottom: 15px;
  }
`

const Prop = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Gilroy-Light';
  font-size: 16px;

  > span {
    display: block;
    width: 100px;
    font-family: 'Gilroy-ExtraBold';
  }
`

const Heading = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 16px;
}
`

const Equipment = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 15px;

  > img {
    width: 32px;
    height: 26px;
  }

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`

const ToRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  font-family: 'Gilroy-Light';
  gap: 7px;
`

/** pffff */
const Font14 = styled.div`
  width: fit-content;
  font-size: 14px;
`

const Font16 = styled.div`
  width: fit-content;
  font-size: 16px;
`

const Font12 = styled.div`
  width: fit-content;
  font-size: 12px;
`

const Green = styled.div`
  color: #06c452;
  margin-bottom: 10px;
  > span {
    font-family: 'Gilroy-ExtraBold';
  }
`

const Price = styled.div`
  margin-top: -10px;
  font-family: 'Gilroy-ExtraBold';
  font-size: 32px;

  @media (max-width: 767px) {
    font-size: 28px;
  }
`

const Button = styled.a`
  text-decoration: none;
  box-sizing: border-box;
  color: #fff;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  background-color: #32c8d2;
  border-radius: 10px;
  padding: 16px 12px;
`

const __shortNameLength = 40;

export default function Offer ({
  id, currency, end, extra, image, model, name, 
  price, start, status, yachtCharter, year, deposit,
  length, berths, cabins, wc, equipment, discount
})
{
  const { lang } = useContext(LangContext);

  if (id == null) {
    return <SkeletonItem />
  }

  const shortName = name.length > __shortNameLength
    ? name.substr(0, __shortNameLength - 3) + '...'
    : name;

  const searchParameters = new URLSearchParams(window.location.search);
  const startDateStr = searchParameters.get('start').split('-');
  const startDate = startDateStr[2] + '.' + startDateStr[1];
  const days = searchParameters.get('days');

  const offerUrl = `/oferta/${id}${window.location.search}&lang=${lang}`

  const renderableEquipment = equipment
    .filter((eq) => __renderable_equipment.includes(eq.id));

  return (
    <Wrapper href={offerUrl}>
      <Image $src={image} />
      <Info>
        <Title>
          {model} | {year}
        </Title>
        <Details>
          {name} <span>|</span> Mainsail: {yachtCharter}
        </Details>
        <Details>
          <img src="/ico/pointer.svg" /> {start}
        </Details>
        <Props>
          <Prop><span>{Translations[lang].length}:</span> {length}</Prop>
          <Prop><span>{Translations[lang].berths}:</span> {berths}</Prop>
          <Prop><span>{Translations[lang].cabins}:</span> {cabins}</Prop>
          <Prop><span>{Translations[lang].wc}:</span> {wc}</Prop>
        </Props>

        {renderableEquipment.length > 0 && <>
          <Heading>
            {Translations[lang].equipmentTitle}:
          </Heading>
          <Equipment>
            {renderableEquipment.map((eq, key) => (
              <img src={`/ico/equipment/${eq.id}.svg`} title={eq.name} key={key} />
            ))}
          </Equipment>
        </>}

        <Details>
          <Heading>{Translations[lang].deposit}:</Heading> {formatNumberByCountry(deposit, lang)} {currency}
        </Details>

        <Details>
          <span>
            <img src="/ico/warn.svg" />
          </span>
          <Heading>
            {Translations[lang].requiredLicence}
          </Heading>
        </Details>
      </Info>
      <Summary>
        <ToRight>
          <Font14>
            status = {status}
          </Font14>
          <Font14>
            {Translations[lang].start} {startDate} ({days} {Translations[lang].days})
          </Font14>
        </ToRight>
        <ToRight>
          {discount > 0 && <Font16><Green>{Translations[lang].discount}: <span>-{discount}%</span></Green></Font16>}
          <Font16>
            {Translations[lang].ourPrice}
          </Font16>
          <Price>
            {formatNumberByCountry(price, lang)} {currency}
          </Price>
          <Font16>
            + {formatNumberByCountry(extra, lang)} {currency} {Translations[lang].extras} 
          </Font16>
          <Button>
            {Translations[lang].checkoutDetails}
          </Button>
        </ToRight>
      </Summary>
    </Wrapper>
  )
}
