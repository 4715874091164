import { useContext, useEffect, useState } from "react";
import styled from "styled-components"
import __server from "../../Api";
import axios from "axios";
import { Translations } from "./Lang";
import { LangContext } from "../../App";
import Offer from "./Offer";
import Pagination from "./Pagination";
import NotFound from "./NotFound";
import { OffersFilterContext, OffersBasesContext } from "./OfertyPage";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    margin-top: 20px;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Totals = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #878b93;

  > span {
    color: #133b51;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Offers = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const SmokeScreen = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  cursor: wait;
  width: 100vw;
  height: 100vh;
  z-index: 999;
`

const fabriaceSkeletonSuperUsefullAndPerfectList = function ()
{
  return {
    total: 7,
    pages: 1,
    data: new Array(7).fill(null),
    smokescreen: true
  }
}

export default function Results ()
{ 
  const { lang } = useContext(LangContext); 
  const [data, setData] = useState(fabriaceSkeletonSuperUsefullAndPerfectList());

  const { filters } = useContext(OffersFilterContext);
  const { setBases } = useContext(OffersBasesContext);

  const isValidGETParameter = (value) =>
       value !== null
    && value !== 'null'
    && !(value instanceof Array && value.length === 0)

  useEffect(() => {
    if (filters.start === null || filters.days === null) {
      return () => {};
    }

    /** Url do histori */
    const validFilters = Object.entries(filters)
      .filter(n => isValidGETParameter(n.at(1)));

    const url = new URL(window.location);
    url.search = new URLSearchParams(validFilters).toString();

    window.history.pushState({}, '', url);

    /** Zapytanie */
    setData(fabriaceSkeletonSuperUsefullAndPerfectList());
    axios.get(`${__server}/front/search${window.location.search}&lang=${lang}`).then((resp) => {
      console.log(data);
      setData(resp.data);
      setBases(resp.data.bases);
    }).catch((err) => {
      console.error(err);
    }) 

  }, [filters]);


  if (data.total === 0) {
    return <NotFound />
  }


  return (
    <Wrapper>
      {data.smokescreen
        ? <SmokeScreen />
        : <Top>
            <Totals>
              {Translations[lang].found}: <span>{data.total} {Translations[lang].variants}</span>
            </Totals>
            <Pagination lastPage={data.pages}/>
          </Top>
      }
      <Offers>
        {data.data.map((offer, key) => (
          <Offer {...offer} key={key} />
        ))}
      </Offers>
    </Wrapper>
  )
}
