import styled from "styled-components"
import { useContext, useEffect, useState } from "react"
import { LangContext } from "../../App"
import __server, { __gcloud } from "../../Api"
import axios from "axios"
import { Translations } from "./Lang"

const Bounding = styled.div`
  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Wrapper = styled.div`
  margin-top: 40px;
  border-radius: 10px;
  background-image: url(/about/safety.webp);
  background-repeat: no-repeat;
  display: flex;

  @media (max-width: 767px) {
    margin-top: 27px;
    flex-direction: column;
    box-sizing: border-box;
  }
`

const Left = styled.div`
  width: 533px;
  box-sizing: border-box;
  padding: 25px 49px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 19px 16px 0px 16px;
  }
`

const Right = styled.div`
  width: calc(1320px - 533px);
  text-align: right;
  box-sizing: border-box;
  padding: 50px 36px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 30px 0px;
  }
`

const TitleWrapper = styled.div`
  translate: -50px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 500px;
`

const TitleImage = styled.div`
  background-color: #fff;
  box-sizing: border-box;
  padding: 10px 20px 10px 50px;
  border-radius: 0px 10px 10px 0px;

  > img {
    width: 120px;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 32px;
  font-weight: 400;
  color: #fff;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    font-size: 28px;
    width: 175px;
  }
`

const Descr = styled.div`
  margin-top: 16px;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  width: 525px;

  > b {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    width: 100%;
    font-size: 16px;
  }
`

const Btn = styled.a`
  text-decoration: none;
  font-family: 'Manrope';
  font-weight: 800;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  padding: 15px 32px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 2px solid #fff;

  @media (max-width: 767px) {
    display: none;
  }
`

const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 36px;
  box-sizing: border-box;
  padding-right: 74px;
  margin-top: -320px;
  min-height: 350px;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: -40px;
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }
`

/**
 * ----------------------------------------
 */


const Card = styled.a`
  text-decoration: none;
  width: 291px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.2);
`

const CardThumb = styled.div`
  height: 182px;
  background-image: url(${props => props.$img});
  background-size: cover;
  background-position: center center;
  border-radius: 10px 10px 0px 0px;
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  box-sizing: border-box;
  padding: 12px 0px 17px 0px;
  align-items: center;
`

const CardTitle = styled.div`
  width: 237px;
  font-family: 'Manrope';
  font-weight: 800;
  font-size: 14px;
  color: #133b51;
  text-transform: uppercase;
  text-align: center;
  height: 30px;
`

const CardProps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`

const CardProp = styled.div`
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 14px;
  color: #133b51;

  > span { 
    font-family: 'Gilroy-ExtraBold';
  }
`

const CardButton = styled.button`
  border-radius: 5px;
  background-color: #32c8d2;
  box-sizing: border-box;
  padding: 11px 36px;
  border: none;
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
`

const MobileButton = styled.div`

  margin-top: 30px;
  display: none;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
  }

  > a {
    text-decoration: none;
    color: #1a87c5;
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 700;
    border: 2px solid #1a87c5;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 15px 33px;
  }
`


export default function CompanyCruiseSection ()
{
  const { lang } = useContext(LangContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${__server}/front/incentive/promoted?lang=${lang}`).then((resp) => {
      setData(resp.data);
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
    })

  }, []);
  

  return (
    <Bounding>
      <Wrapper>
        <Left>
          <TitleWrapper>
            <TitleImage>
              <img src="/menu/incentive.svg" />
            </TitleImage>
            <Title>
              {Translations[lang].title}
            </Title>
          </TitleWrapper>
          <Descr>
            {Translations[lang].descr}
          </Descr>
        </Left>
        <Right>
          <Btn href="/incentive/rejsy-firmowe">
            {Translations[lang].companyCruises}
          </Btn>
        </Right>
      </Wrapper>
      <Bottom>
        {data.map((card, key) => (
          <Card key={key} href="/incentive/rejsy-firmowe">
            <CardThumb $img={`${__gcloud}/${card.image}`} />
            <CardContent>
              <CardTitle>
                {card.title}
              </CardTitle>
              <CardProps>
                <CardProp>
                 {Translations[lang].departure}: <span>{card.departure}</span>
                </CardProp>
                <CardProp>
                 {Translations[lang].duration}: <span>{card.duration}</span>
                </CardProp>
              </CardProps>
              <CardButton>
                {Translations[lang].checkOut}
              </CardButton>
            </CardContent>
          </Card>
        ))}
      </Bottom>
      <MobileButton>
        <a href="/incentive/rejsy-firmowe">
          {Translations[lang].companyCruises}
        </a>
      </MobileButton>
    </Bounding>
  )
}
